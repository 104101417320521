import React, { useState, Component, useEffect } from "react";
import { Link, graphql } from "gatsby";
import useInViewPort from "../hooks/useInViewPort";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import CardBlog from "../components/CardBlog";

import DecorationBlob3 from "../images/decorations/deco-blob-3.svg";

import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";

import LogoThinkpricingGrey from "../images/logos/brand/grey-thinkpricing.svg";
import LogoKinteract from "../images/logos/brand/grey-kinteract.svg";
import LogoQolo from "../images/logos/brand/qolo-colored.svg";
import QoloReview from "../images/logos/qolo-review.svg";
import ThinkpricingReview from "../images/logos/thinkpricing-review.svg";
import AgentadvanceReview from "../images/logos/agentadvance-review.svg";
import Testimonials from "../components/Testimonials";

import LogoUXCollective from "../images/logos/brand/uxcollective.svg";
import LogoPrototypr from "../images/logos/brand/prototypr.svg";
import LogoHackernoon from "../images/logos/brand/hackernoon.svg";
import LogoTNW from "../images/logos/brand/tnw.svg";
import LogoSM from "../images/logos/brand/smashing-magazine.svg";

import Avatar1 from "../images/avatars/team/avatar1.png";
import Avatar2 from "../images/avatars/team/avatar2.png";
import Avatar3 from "../images/avatars/team/avatar3.png";
import Avatar4 from "../images/avatars/team/avatar4.png";
import Avatar5 from "../images/avatars/team/avatar5.png";
import Avatar6 from "../images/avatars/team/avatar6.png";
import Avatar7 from "../images/avatars/team/avatar7.png";
import Avatar8 from "../images/avatars/team/avatar8.png";
import Avatar9 from "../images/avatars/team/avatar9.png";
import Avatar12 from "../images/avatars/team/avatar12.png";

import IconSamsung from "../images/logos/brand/grey-samsung.svg";
import IconHubspot from "../images/logos/brand/grey-hubspot.svg";
import IconRedBull from "../images/logos/brand/grey-red-bull.svg";
import IconTMobile from "../images/logos/brand/grey-t-mobile.svg";
import IconAioneersColored from "../images/logos/brand/aioneers-colored.svg";
import IconQoloColored from "../images/logos/brand/qolo-colored.svg";
import IconTMobileColored from "../images/logos/brand/t-mobile-colored.svg";
import IconSamsungColored from "../images/logos/brand/samsung-colored.svg";
import IconThinkPricingColored from "../images/logos/brand/think-pricing-colored.svg";
import IconKinteractColored from "../images/logos/brand/kinteract-colored.svg";

import SvgDivider2 from "../images/dividers/divider-2.inline.svg";
import DecorationDots5 from "../images/decorations/deco-dots-5.svg";
import DecorationDots6 from "../images/decorations/deco-dots-6.svg";
import DecorationBlob6 from "../images/decorations/deco-blob-6.svg";
import DecorationLine1 from "../images/decorations/deco-lines-1.svg";
import DecorationLine6 from "../images/decorations/deco-lines-6.svg";
import Divider1 from "../images/dividers/divider-3.svg";

import IconCheck from "../images/icons/interface/check.svg";
import IconPointer from "../images/icons/pages/home/pointer.svg";
import IconGlobe from "../images/icons/pages/home/globe.svg";
import IconBulb from "../images/icons/pages/home/bulb.svg";
import IconCross from "../images/icons/interface/cross.svg";
import IconRoad from "../images/icons/theme/tools/road-cone.svg";
import IconShovel from "../images/icons/theme/tools/shovel.svg";


// import Divider2 from "../images/dividers/divider-2.svg";
// import Divider2 from '../images/dividers/divider-2.custom.svg';

import { GatsbyImage } from "gatsby-plugin-image";
import QuoteIconGrey from "..//images/icons/theme/text/quote-1-grey.svg";
//import { useMixpanelTracker } from "../hooks/useMixpanelTracker";
import { IndexJsonLd } from "../components/jsonld/IndexJsonLd";
// import DecorationBlob19 from "../images/decorations/deco-blob-19.svg";


const toggleAccordion = e => {
  const panel = e.target;
  const panelAccordionContent = panel.querySelector(`.accordion-content`);
  const panelIcon = panel.querySelector(".icon");

  if (panelAccordionContent.className.includes("collapse")) {
    panelAccordionContent.classList.remove("collapse");
    panelIcon.classList.add("icon-rotate");
  } else {
    panelAccordionContent.classList.add("collapse");
    panelIcon.classList.remove("icon-rotate");
  }
};

const DatoAsset = ({ src, alt, className, style, lazy = true }) => (
  <picture>
    <source
      type="image/webp"
      srcSet={`${src}?fm=webp&w=200 200w,
${src}?fm=webp&w=400 400w,
${src}?fm=webp&w=800 800w,
${src}?fm=webp&w=1200 1200w,
${src}?fm=webp&w=1436 1436w`}
      sizes="(max-width: 800px) 100vw, 800px"
    />
    <source
      srcSet={`${src}?w=200 200w,
${src}?w=400 400w,
${src}?w=800 800w,
${src}?w=1200 1200w,
${src}?w=1436 1436w`}
      sizes="(max-width: 800px) 100vw, 800px"
    />
    <img
      sizes="(max-width: 800px) 100vw, 800px"
      srcSet={`${src}?fm=webp&w=200 200w,
${src}?w=400 400w,
${src}?w=800 800w,
${src}?w=1200 1200w,
${src}?w=1436 1436w`}
      src={`${src}?w=1200`}
      alt={alt || ""}
      className={className || ""}
      style={style || {}}
      loading={lazy ? "lazy" : "eager"}
    />
  </picture>
);

const AiSaaSSurveyPage = ({ data }) => {
  const FeaturedArticlesData = data.featuredArticles.edges;


  return (
    <Layout isMainPage footerBgClass="bg-primary-alt">
      <div className="pillar-page about-us-page">
        <IndexJsonLd />
        <SectionFirstScreen data={data} />
        <ContactsBlockTop data={data}/>
        <SectionWhoWereLooking data={data} />
        <SectionWhyUXConsulting data={data} />
        <SectionWhoWeAre data={data} />
        <SectionBlog FeaturedArticlesData={FeaturedArticlesData} />

        {/* <SectionFeaturedProducts data={data} /> */}
        {/* <SectionIndustries data={data} /> */}
        {/* <SectionTopUXAgency data={data} /> */}
        {/* <SectionOurBook data={data} /> */}
        
        
      </div>

      <ContactsBlock />
    </Layout>
  );
};



const SectionFirstScreen = ({ data }) => {
  const ImageHero = data.ImageHero.childImageSharp.gatsbyImageData;

  return (
    <section className="o-hidden has-divider first-section bg-primary-3">
      <div className="container pb-3">
        <div className="row align-items-center text-light">
          <div className="col-10 mx-auto text-center mb-4 mb-lg-0">

            <h1 className="display-4 mb-2">AI and SaaS: Measuring the perceived and factual Value</h1> 

            <span className="pb-2 pre-heading text-light">
              An Industry Survey
            </span>

            

            {/* <Link
              to="/improve-your-product"
              target="_blank"
              id="freeconsultation"
              className="btn btn-lg btn-primary lead-cta"
            >
              Book 1h Consultation
            </Link> */}


            {/* <a
              className="mt-4 mt-md-0 ml-0 ml-md-2 btn btn-lg btn-outlined text-primary button lead-cta"
              id="freeconsultation"
              target="_blank"
              href="/start-from-scratch"
            >
              Start from scratch
            </a> */}
          </div>
          {/* <div
            className="col-lg-5 text-center "
            style={{ position: "relative" }}
          >
            <div className="min-vw-50">
              <GatsbyImage
                alt={"ImageApp"}
                style={{ maxWidth: 800 }}
                image={ImageHero}
                loading="eager"
              />
            </div>
          </div> */}
        

          <div className="col-12 mt-6 text-left row align-items-center publications-section">
            <p className="col-12 col-sm-auto lead">Featured in</p>
            <div className="col-12 col-lg-10 mb-0 align-items-center">
              <ul className="d-flex publications-list mb-0 align-items-center list-unstyled">
                
                <li>
                  <a href="https://www.smashingmagazine.com/2021/02/building-user-trust-in-ux-design/" target="_blank">
                    <LogoSM className="mb-2" />
                  </a>
                </li>
                
                <li>
                  <a href="https://uxdesign.cc/why-should-product-teams-use-wireframes-more-often-60e34a2bc55" target="_blank">
                    <LogoUXCollective className="mb-2" />
                  </a>
                </li>
                
                <li>
                  <a href="https://blog.prototypr.io/design-thinking-demystified-a-manual-for-innovation-66925ad1f86a" target="_blank">
                    <LogoPrototypr className="mb-2" />
                  </a>
                </li>
                
                <li>
                  <a href="https://thenextweb.com/news/a-z-guide-to-launching-your-digital-product" target="_blank">
                    <LogoTNW className="mb-2" />
                  </a>
                </li>
                {/* <li>
                  <LogoModus className="mb-2" />
                </li> */}
                
                <li>
                  <a href="https://hackernoon.com/u/adamfard" target="_blank">
                    <LogoHackernoon className="mb-2" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <SvgDivider2 className="bg-primary-alt"/>
      </div>

      <div className="decoration-wrapper d-none d-lg-block">
        <div
          className="decoration scale-1 "
          style={{ top: "30%", right: -30 }}
        >
          <DecorationBlob6 className="bg-yellow" />
        </div>

        <div className="decoration scale-1 left" style={{ bottom: 20 }}>
          <DecorationDots5 className="bg-primary" />
        </div>
      </div>
    </section>
  );
};

const ContactsBlockTop = ({ data }) => {
  const Participants = data.Participants.childImageSharp.gatsbyImageData;
  const Time = data.Time.childImageSharp.gatsbyImageData;
  const Questions = data.Questions.childImageSharp.gatsbyImageData;

  return (
    <section
      className="section-contacts section-contacts-block bg-primary-alt pb-4"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-10 mx-auto text-center">
            <h2 className="h1 mb-2">The Study</h2>
            <div className="lead">
              We're conducting an industry-wide research about the perceived and factual value of AI within the SaaS industry.
            </div>

            
          
          </div>
          {/* <div className="col-12 col-md-6">
            <div className="card card--common card-body">
              <div id="activecampaign-form">
                <div id="activecampaign-form__wrapper">
                  <div className=" _form_67" />
                </div>
              </div>
            </div>
          </div> */}

        </div>

        <div className="row mt-4 d-flex">
          <div className="col-12 col-md-4 col-lg-4">
            <div
              className="card card-body noshadow bg-primary-alt"
              style={{ height: "calc(100% - 1.5rem)" }}
            >
              <div className="align-items-center text-align-center mb-3">
                <GatsbyImage
                  image={Participants}
                  alt=""
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  height={120}
                  style={{ height: "120px" }}
                  loading={"lazy"}
                />
              </div>

              <h4 className="mb-2">20 participants</h4>
              <p className="lead">
                We’ll talk to 20 SaaS industry professsionals to get their opinion on the potential and factual value of AI.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <div
              className="card card-body noshadow bg-primary-alt"
              style={{ height: "calc(100% - 1.5rem)" }}
            >
              <div className="align-items-center mb-3">
                <GatsbyImage
                  image={Time}
                  alt=""
                  style={{ height: "120px" }}
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  loading={"lazy"}
                />
              </div>

              <h4 className="mb-2">30 min. per interview</h4>
              <p className="lead">
                We know you’re busy. That’s why we’ll do out best to get the msot ouf of the 30 minutes of time we have for the interview.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <div
              className="card card-body noshadow bg-primary-alt"
              style={{ height: "calc(100% - 1.5rem)" }}
            >
              <div className="align-items-center mb-3">
                <GatsbyImage
                  image={Questions}
                  alt=""
                  style={{ height: "120px" }}
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  loading={"lazy"}
                />
              </div>

              <h4 className="mb-2">20 questions</h4>
              <p className="lead">
                Depending on how comprehensive your answers are, we shouldn’t go beyond 15-20 questions. 
              </p>
            </div>
          </div>
        </div>

      </div>

    </section>
  );
};

const SectionWhoWeAre = ({ data }) => {
  return (
    <section className="fifth-section py-4">
      <div className="container pt-0">
        <div className="row mt-7">
          <div className="col-md-12 col-lg-7 pl-3 pr-3 p-lg-0">
            <h2 className="product-design-heading2">Who we are </h2>
            <p className="lead">
              Having been actively working alongside SaaS businesses for almost 6 years now, 
              we’re looking to deepen our industry understanding and share the insights with anyone interested.
            </p>

            <p className="lead">
              Our primary field of expertise is product design and UX research with a focus on SaaS, Fintech and EdTech industries.
            </p>

            <div className="trusts-container">
              <span className="trusts-heading">Trusted By </span>
              <div className="partners-container mt-3 mb-4 mb-lg-0">
                <IconSamsung
                  className="partner mb-4 mb-lg-0"
                  alt="samsung"
                />
                <IconTMobile
                  className="partner mb-4 mb-lg-0"
                  alt="t-mobile"
                />
                <IconHubspot
                  className="partner mb-4 mb-lg-0"
                  alt="hubspot"
                />
                <IconRedBull
                  className="partner mb-4 mb-lg-0"
                  alt="red-bull"
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-5 text-center">
            <div className="team-wrapper m-auto d-inline d-lg-inline-flex">
              
              {/* Adam */}
              <img
                src={Avatar1}
                className="team-member"
                alt="team-member"
              />

              {/* Adam Fed */}
              <img
                src={Avatar2}
                className="team-member"
                alt="team-member"
              />

              {/* Anastasia */}
              <img
                src={Avatar3}
                className="team-member"
                alt="team-member"
              />

              {/* Anastasiya */}
              <img
                src={Avatar4}
                className="team-member"
                alt="team-member"
              />

              {/* Dima */}
              <img
                src={Avatar5}
                className="team-member"
                alt="team-member"
              />

              {/* Dragana */}
              <img
                src={Avatar12}
                className="team-member"
                alt="team-member"
              />
              
              {/* Joe */}
                {/* <img
                src={Avatar10}
                className="team-member"
                alt="team-member"
              /> */}

              {/* Kasia */}
              <img
                src={Avatar6}
                className="team-member"
                alt="team-member"
              />

              {/* Nicole */}
              <img
                src={Avatar7}
                className="team-member"
                alt="team-member"
              />

              {/* Raoni */}
              <img
                src={Avatar8}
                className="team-member"
                alt="team-member"
              />

              {/* Stefan */}
              <img
                src={Avatar9}
                className="team-member"
                alt="team-member"
              />
              
              {/* <img
                src={Avatar11}
                className="team-member"
                alt="team-member"
              /> */}
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );

};

const SectionWhyUXConsulting =({ data }) => {
  const missionImg = data.missionImg.childImageSharp.gatsbyImageData;

  return (
    <section className="second-section py-4">
      <div className="container p-0">
        <div className="row d-none d-xl-flex align-items-center justify-content-between o-hidden">
          <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-center">
            <h2 className="heading-two">Why participate?</h2>

            <p className="lead description m-0">
            You’re welcome to join our research as an interviewee! Ideally,
             we’re looking for decision-makers within the SaaS industry, 
             who understand first-hand how their busineses operate.
            </p>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row mt-1 mt-xl-6">
          <div className="col-md-12 col-xl-6 pl-5 pr-5 pl-xl-0 pr-xl-0">
            <GatsbyImage
              className="m-md-auto m-xl-0 vision-img"
              loading={"lazy"}
              image={missionImg}
              alt="Vision"
            />
          </div>

          <div className="col-10 d-xl-none p-2 my-2 mx-auto">
            <h2 className="heading-two">Why participate?</h2>

            <p className="lead description m-0">
            You’re welcome to join our research as an interviewee! Ideally,
             we’re looking for decision-makers within the SaaS industry, 
             who understand first-hand how their busineses operate.
            </p>
          </div>

          <div className="col-md-12 col-xl-6 impacts-container">
            <div className="impact-wrapper">
              <h3 className="impact-heading">Benefits:</h3>
              <ul className="impact-benefit-list">
                <li className="impact-benefit-list-item">
                  Get a post-research report
                </li>
                <li className="impact-benefit-list-item">
                  See what the whole industry is up to 
                </li>
                <li className="impact-benefit-list-item">
                  Learn your competitors’ next moves
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


const SectionWhoWereLooking =({ data }) => {
  return (
    <section className="py-4">
      <div className="container">
        <div className="row mt-4 d-flex">
          
          <div className="col-12 col-md-10 mx-auto mb-4 text-center">
            <h2 className="h1 mb-2">Who we’re looking for</h2>
            <div className="lead">
            The participants we’re looking for are the decision-makers within <strong>SaaS</strong> products.
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <div
              className="card card-body noshadow"
              style={{ height: "calc(100% - 1.5rem)" }}
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  alt=""
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  height={160}
                  style={{ height: "160px" }}
                  loading={"lazy"}
                />
              </div>

              <h4>Chief-level executives</h4>
              <p className="lead">
                We admire the expertise you have in the ins and outs of how your product operates.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <div
              className="card card-body noshadow"
              style={{ height: "calc(100% - 1.5rem)" }}
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  alt=""
                  style={{ height: "160px" }}
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  loading={"lazy"}
                />
              </div>

              <h4>Product owners</h4>
              <p className="lead">
              We can't appreciate enough the high-level product vision and strategy you have.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <div
              className="card card-body noshadow"
              style={{ height: "calc(100% - 1.5rem)" }}
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  alt=""
                  style={{ height: "160px" }}
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  loading={"lazy"}
                />
              </div>

              <h4>Senior management</h4>
              <p className="lead">
                We'd love to get into the nitty-gritty of running a team in your SaaS product. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


const ContactsBlock = ({ isInViewProp }) => {
  const [ref, isInView] = useInViewPort();
  return (
    <section
      ref={ref}
      className="section-contacts section-contacts-block bg-primary-alt pb-0 position-relative"
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <h2 className="display-4">Register now!</h2>
              <p className="lead">
                It takes just 30 minutes of your time. We’ll do the rest. 
              </p>

              <p className="lead">
                Let’s get ahead of the curve together.
              </p>
          </div>
          <div className="col-12 col-md-6 section-contacts__form">
            <div className="card card--common card-body">
              <div id="activecampaign-form">
                <div id="activecampaign-form__wrapper">
                  <div className=" _form_67" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <Helmet>
          <script
            src="https://adamfard48700.activehosted.com/f/embed.php?id=67"
            async
          />
        </Helmet>

    </section>
  );
};


const SectionTrustedCompanies = () => (
  <section className="pt-0">
    <div className="container">
      <div className="row text-center mb-3">
        <div className="col">
          <small className="">Trusted by companies like</small>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ul className="Companieslogo d-flex align-items-center justify-content-center list-unstyled">
            <li>
              <a href="/projects/ux-design-samsung-app-testing-platform"><LogoSamsung className="" /></a>
            </li>
            <li>
              <a href="/contact-us"><LogoTmobile className="" /></a>
            </li>
            <li>
              <a href="/projects/edtech-saas-design"><LogoKinteract className="" /></a>
            </li>
            <li>
              <a href="/projects/saas-b2b-platform"><LogoThinkpricingGrey className="" /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);


const SectionOurBook = ({ data }) => {
  const ImageBook = data.ImageBook.childImageSharp.gatsbyImageData;

  return (
    <section className="text-light py-2 py-md-1 o-hidden bg-primary eighth-section">
      <div className="container align-items-center text-center text-md-left bg-primary">
        <div className="row py-6 align-items-center">
          <div className="col-12 col-md-6 text-center pb-4 pb-md-0">
            <GatsbyImage
              image={ImageBook}
              alt="The Art of Launching a Product For Startups Cover"
              className=""
              loading={"lazy"}
            />
          </div>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <div>
              <span className="badge badge-primary-2 mb-2 lead">
                Handbook for Startups & Product Owners
              </span>
              <h2 className="h1">The Art of Launching a Product</h2>
              <p className="lead">
                Learn step-by-step, how to perfectly plan and execute the launch
                of your digital product.
              </p>
              <a
                href=" https://adamfard.com/download-ux-resources/launch-digital-product"
                className="btn btn-lg btn-white mt-3"
              >
                Download Free Book
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionBlog = ({ FeaturedArticlesData }) => (
  <section className="o-hidden pb-0 pb-md-5 pb-xl-7 mb-3 ninth-section">
    <div className="container pb-6">
      <div className="decoration-container w-100 position-relative d-none d-md-block">
        <div className="decoration-wrapper w-100 position-relative">
          <div className="decoration position-relative w-100">
            <DecorationBlob3 className="bg-primary-alt decoration-blob-3" />
          </div>
        </div>
      </div>
      <div className="row pl-3 pl-md-0">
        <h2 className="display-4">Other pieces we’ve published</h2>
      </div>
      <div className="row pl-3 pl-md-0 lead">
        <Link to="/blog" className="hover-arrow">
          Read All UX Blogs
        </Link>
      </div>
      <div className="row mt-4">
        {FeaturedArticlesData.map(({ node }) => (
          <div className="col-md-6 col-lg-4" key={node.id}>
            <CardBlog cardData={node} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default AiSaaSSurveyPage;

export const query = graphql`
  query AiSaaSSurveyQuery {
    ImageGrow: file(relativePath: { eq: "photos/expertise.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    ImageIndustries: file(
      relativePath: { eq: "photos/transforming_business_ux.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    ImageOurProcess: file(
      relativePath: { eq: "photos/ux-design-process.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    ImageBook: file(
      relativePath: { eq: "photos/The-Art-of-Launching-a-Digital-Product.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    ImageHero: file(relativePath: { eq: "photos/ux-design-agency-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1800)
      }
    }
    Participants: file(
      relativePath: { eq: "logos/brand/participants.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    Time: file(
      relativePath: { eq: "logos/brand/time.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    Questions: file(relativePath: { eq: "logos/brand/questions.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    agentAvatar: file(relativePath: { eq: "photos/agent-avatar.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, height: 400, layout: FIXED)
      }
    }
    thinkpricingAvatar: file(relativePath: { eq: "photos/think-avatar.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, height: 400, layout: FIXED)
      }
    }
    qoloAvatar: file(relativePath: { eq: "photos/qolo-avatar.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, height: 400, layout: FIXED)
      }
    }
    projectsThinkpricingImg: file(
      relativePath: { eq: "photos/home-projects-thinkpricing.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    projectsKinteractImg: file(
      relativePath: { eq: "photos/home-projects-kinteract.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    projectsQoloImg: file(
      relativePath: { eq: "photos/home-projects-qolo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    fintechImg: file(relativePath: { eq: "photos/Fintech.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    healthcareImg: file(relativePath: { eq: "photos/Healthcare.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    saasImg: file(relativePath: { eq: "photos/SaaS.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    communicationImg: file(relativePath: { eq: "photos/IT-Communication.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    visionImg: file(relativePath: { eq: "photos/about-us-vision.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    missionImg: file(relativePath: { eq: "photos/about-us-mission.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    educationImg: file(relativePath: { eq: "photos/Education.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    itImg: file(relativePath: { eq: "photos/home-it.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    featuredArticles: allDatoCmsArticle(
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            gatsbyImageData(width: 544, layout: CONSTRAINED)
          }
          category {
            name
          }
          id
          slug
        }
      }
    }
  }
`;
