import React from "react";

import QuoteIcon from "../images/icons/theme/text/quote-1.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import * as PropTypes from "prop-types";
import IconStar from "../images/icons/theme/general/star.svg";

const Testimonials = ({testimonials, title, className}) => {
    const itemClass = testimonials.length > 2 ? "col-12 col-md-4 col-lg-4" : testimonials.length > 1 ? "col-12 col-md-6 col-lg-6" : "col-12"

    return (
        <section className={"testimonials " + (className || "")}>
            <div className="container">
                {
                    title && (
                        <div className="row text-center">
                            <div className="col-md-8 text-left">
                                <h2 className="display-4">{title}</h2>
                            </div>
                        </div>
                    )
                }

                <div className="row mt-4 d-flex">
                    {testimonials.slice(0, 3).map((t, i) => (
                        <div key={i} className={itemClass}>
                            <div key={i} className="card card-body card--no-hover">
                                <div>
                                    <div className="mb-2">
                                        {/* <QuoteIcon className="icon icon-lg align-items-left mb-2 bg-primary-2"/>
                                         */}
                                        <IconStar className="icon icon-md align-items-left bg-primary-2"/>
                                        <IconStar className="icon icon-md align-items-left bg-primary-2"/>
                                        <IconStar className="icon icon-md align-items-left bg-primary-2"/>
                                        <IconStar className="icon icon-md align-items-left bg-primary-2"/>
                                        <IconStar className="icon icon-md align-items-left bg-primary-2"/>
                                    </div>
                                    
                                    <p className="">
                                        {t.content.split('**').map((part, index) => 
                                            index % 2 === 0 ? <span key={index}>{part}</span> : <strong className="highlight" key={index}>{part}</strong>
                                        )}
                                    </p>

                                </div>
                                <div className="d-block d-flex align-items-center mt-2">
                                    <GatsbyImage
                                        image={t.gatsbyImageData || t.imageFixed}
                                        style={{ width: "64px", height: "64px"}}
                                        alt=""
                                        imgClassName="avatar align-items-left mr-1"
                                        imgStyle={{margin: 0}}
                                        loading={"lazy"} />
                                    {t.logo}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

Testimonials.propTypes = {
    testimonials: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string.isRequired,
        imageFixed: PropTypes.object.isRequired,
        logo: PropTypes.element.isRequired
    })).isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
}

export default Testimonials;
